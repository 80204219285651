import { useState } from 'react';

/**
 * Similar to the useState hook but uses a comparator to set
 * the state only if the new state is different to avoid re-rendering components
 * @param {S}                 initialValue The initial value for the state
 * @param {(S, S) => boolean} equator      A function that returns true only if the two given values are equal
 */
export function useSafeState<S>(
    initialValue: S,
    equator: (oldState: S, newState: S) => boolean,
): [S, (arg1: ((arg1: S) => S) | S) => void] {
    const [state, setState] = useState<S>(initialValue);

    const smartSetState = (newState: ((arg1: S) => S) | S) => {
        const newStateValue =
            // @ts-expect-error [TS migration] (previously $FlowIgnore) flow can't see that we make sure newState is a function before calling it
            typeof newState === 'function' ? newState(state) : newState;

        if (!equator(state, newStateValue)) {
            setState(newStateValue);
        }
    };
    return [state, smartSetState];
}
