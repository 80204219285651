import i18n, { InitOptions } from 'i18next';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';
import { camelize, snakify } from 'helpers/syntaxHelper';
import { getClientBind } from 'store/APIClient';
import { storybookConfig } from '../.storybook/i18n.storybook';

const frontLocales = ['components', 'sentence'];

const localesSuffix = DEV ? '.json' : `.${process.env.COMMIT_HASH}.json`;

function loadTranslationPath(lngs: string[], namespaces: string[]) {
    return frontLocales.includes(namespaces[0])
        ? `/locales/${lngs[0]}/${namespaces[0]}${localesSuffix}`
        : `${VISION_PATH}/locales/${lngs[0]}/${namespaces[0]}`;
}

/**
 * Get the paths for each combination of language and namespace.
 * @example [{lng: 'fr', namespace: 'blocks_manty', path: '/locales/fr/blocks_manty.json'}]
 */
export const getTranslationsPaths = (
    lngs: string[],
    namespaces: string[],
): { lng: string; namespace: string; path: string }[] =>
    lngs.flatMap((lng) =>
        namespaces.map((namespace) => ({
            lng,
            namespace,
            path: loadTranslationPath([lng], [namespace]),
        })),
    );

/**
 * For some client, we override some translation here to meet their requirements.
 * For instance, we change the name of a metric in our app.
 *
 * NB: we use a callback because we want all the translations to be loaded
 * before we override a translation.
 */
const overrideCallback = () => {
    const bind = getClientBind();
    const overrideBlocksMantyShort: Record<string, Record<string, string>> = {};
    const overrideBlocksManty: Record<string, Record<string, string>> = {};
    const overrideComponents: Record<string, Record<string, string>> = {};

    // TODO this is temporary, we probably want to move this config in the backend
    if (bind === 'maisons-laffitte') {
        overrideBlocksMantyShort.budgetLine = {
            amountRequested: 'pré-arbitré',
        };
        overrideBlocksManty.budgetLine = { amountRequested: 'pré-arbitré' };
    }
    if (['demo-budget-1', 'demo-budget-8'].includes(bind)) {
        overrideBlocksMantyShort.orgChartFinance = {
            tree: 'CDR de rattachement',
            code: 'Code du CDR de rattachement',
        };
        overrideBlocksManty.orgChartFinance = {
            tree: 'CDR de rattachement',
            code: 'Code du CDR de rattachement',
        };
        overrideBlocksMantyShort.gestionnaire = {
            '': 'CDR gestionnaires',
            name: 'CDR gestionnaire',
            code: 'Code du CDR gestionnaire',
        };
        overrideBlocksManty.gestionnaire = {
            '': 'CDR gestionnaires',
            name: 'CDR gestionnaire',
            code: 'Code du CDR gestionnaire',
        };
        overrideBlocksMantyShort.antenna = {
            '': 'Politiques sectorielles',
            name: 'Politique sectorielle',
            code: 'Code de la politique sectorielle',
        };
        overrideBlocksManty.antenna = {
            '': 'Politiques sectorielles',
            name: 'Politique sectorielle',
            code: 'Code de la politique sectorielle',
        };
        overrideComponents.budgetProposal = {
            viewByResource: 'Vue par CDR de rattachement',
        };
    }
    if (['centralesupelec', 'demo-sifac'].includes(bind)) {
        overrideBlocksMantyShort.operation = {
            '': 'Programme de financement',
            name: 'Programme de financement',
            code: 'Code du programme de financement',
            softwareId: 'Programme de financement',
        };
        overrideBlocksManty.operation = {
            '': 'Programme de financement',
            name: 'Programme de financement',
            code: 'Code du programme de financement',
            softwareId: 'Programme de financement',
        };
        overrideBlocksMantyShort.orgChartFinance = {
            '': 'Centre financier',
            tree: 'Centre financier',
            code: 'Code du centre financier',
            softwareId: 'Centre financier',
        };
        overrideBlocksManty.orgChartFinance = {
            '': 'Centre financier',
            tree: 'Centre financier',
            code: 'Code du centre financier',
        };
        overrideBlocksMantyShort.chapter = {
            '': 'Compte budgétaire',
            code: 'Compte budgétaire',
            softwareId: 'Compte budgétaire',
            label: "Nom d'un compte budgétaire",
        };
        overrideBlocksManty.chapter = {
            '': 'Compte budgétaire',
            code: 'Compte budgétaire',
            softwareId: 'Compte budgétaire',
            label: "Nom d'un compte budgétaire",
        };
        overrideBlocksMantyShort.budgetLine = {
            softwareId: 'Catégorie budgétaire',
            amountVoted: 'Budget initial',
            amountRealized: 'Exécuté',
            percentRealized: '% Exécuté',
            code: 'Catégorie budgétaire',
            lineCode: 'Code de la catégorie budgétaire',
            amountVotedSup: 'Budget rectificatif',
            amountMandated: 'Payé',
            percentMandated: '% Payé',
            percentMandatedMonthly: 'Taux de paiement à date',
            amountRequested: 'Proposé (logiciel finances)',
        };
        overrideBlocksManty.budgetLine = {
            softwareId: 'Catégorie budgétaire',
            amountVoted: 'Montant initial budgété',
            amountRealized: 'Montant exécuté',
            percentRealized: "Taux d'exécution",
            code: 'Catégorie budgétaire',
            lineCode: 'Code de la catégorie budgétaire',
            amountVotedSup: 'Montant rectificatif',
            amountMandated: 'Montant payé',
            percentMandated: 'Taux de paiement',
            percentMandatedMonthly: 'Taux de paiement à date',
            amountRequested:
                'Montant proposé par les centres financiers (logiciel finances)',
        };
        overrideBlocksMantyShort.analytic = {
            softwareId: 'Domaine fonctionnel / Fonds',
            code: 'Code du Domaine fonctionnel / Fonds',
            name: 'Domaine fonctionnel / Fonds',
            '': 'Domaine fonctionnel / Fonds',
        };
        overrideBlocksManty.analytic = {
            softwareId: 'Domaine fonctionnel / Fonds',
            code: 'Code du Domaine fonctionnel / Fonds',
            name: 'Domaine fonctionnel / Fonds',
            '': 'Domaine fonctionnel / Fonds',
        };
        overrideComponents.budgetProposal = {
            proposition_services: 'Proposition des centres financiers',
            supplementary_budget: 'Budget Rectificatif',
            operation: 'Programme de financement',
        };
        overrideBlocksMantyShort.function = {
            softwareId: 'Domaine fonctionnel',
            '': 'Domaines fonctionnels',
            label: 'Domaine fonctionnel',
            number: 'Domaine fonctionnel (code)',
        };
        overrideBlocksManty.function = {
            softwareId: 'Domaine fonctionnel',
            '': 'Domaines fonctionnels',
            label: 'Domaine fonctionnel',
            number: 'Code du domaine fonctionnel',
        };
        overrideBlocksManty.nature = {
            softwareId: 'Fonds',
            '': 'Fonds',
            number: 'Code du fonds',
            label: 'Fonds',
        };
        overrideBlocksMantyShort.nature = {
            softwareId: 'Fonds',
            '': 'Fonds',
            number: 'Fonds (code)',
            label: 'Fonds',
        };
        overrideBlocksManty.serviceProposal = {
            '': '1. Proposition des centres financiers',
        };
        overrideBlocksMantyShort.serviceProposal = {
            '': '1. Proposition des centres financiers',
        };
        overrideBlocksManty.primitiveBudget = {
            '': '3. Budget initial voté',
            amount: 'Montant initial voté',
        };
        overrideBlocksMantyShort.primitiveBudget = {
            '': '3. Budget initial voté',
            amount: 'Budget initial voté',
        };
        overrideBlocksManty.supplementaryBudgetInscription = {
            '': '4. Budget rectificatif',
            amount: 'Montant rectificatif',
        };
        overrideBlocksMantyShort.supplementaryBudgetInscription = {
            '': '4. Budget rectificatif',
            amount: 'Budget rectificatif',
        };
        overrideBlocksMantyShort.budget = {
            code: 'Perimètre financier',
        };
        overrideBlocksManty.budget = {
            code: 'Perimètre financier',
        };
        overrideBlocksMantyShort.engagement = {
            amountMandated: "Montant payé de l'engagement",
            percentMandated: "Taux de paiement de l'engagement",
        };
        overrideBlocksManty.engagement = {
            amountMandated: "Montant payé de l'engagement",
            percentMandated: "Taux de paiement de l'engagement",
        };
        overrideBlocksMantyShort.mandate = {
            '': 'Paiements',
            amountAdminAccount: 'Montant CA du paiement',
            mandateDate: 'Date de paiement',
            label: 'Libellé du paiement',
            mandateType: 'Type de paiement',
        };
        overrideBlocksManty.mandate = {
            '': 'Paiements',
            amountAdminAccount: 'Montant CA du paiement',
            mandateDate: 'Date de paiement',
            label: 'Libellé du paiement',
            mandateType: 'Type de paiement',
        };
        overrideBlocksMantyShort.marketLot = {
            amountMandated: 'Montant payé du lot de marché',
        };
        overrideBlocksManty.marketLot = {
            amountMandated: 'Montant payé du lot de marché',
        };
        overrideBlocksMantyShort.market = {
            amountMandated: 'Montant payé CA du marché',
        };
        overrideBlocksManty.market = {
            amountMandated: 'Montant payé CA du marché',
        };
    }
    // we patch the existing jsons
    i18n.addResourceBundle(
        'fr',
        'blocks_manty_short',
        overrideBlocksMantyShort,
        true,
        true,
    );
    i18n.addResourceBundle(
        'fr',
        'blocks_manty',
        overrideBlocksManty,
        true,
        true,
    );
    i18n.addResourceBundle('fr', 'components', overrideComponents, true, true);
};

const appConfig: InitOptions = {
    fallbackLng: ['fr', 'en'],
    whitelist: ['fr', 'en'],
    // have a common namespace used around the full app
    ns: [
        'components',
        'blocks_manty',
        'blocks_manty_descriptions',
        'blocks_manty_values',
        'blocks_manty_short',
        'blocks_manty_software_CIRIL',
        'blocks_manty_software_ASTRE',
        'sentence',
        'backend',
    ],
    defaultLocale: 'fr',
    defaultNS: 'components',
    debug: false,
    backend: {
        allowMultiLoading: false,
        loadPath: loadTranslationPath,
    },
    interpolation: {
        escapeValue: false, // not needed for react!!
    },
    react: {
        // @ts-expect-error
        wait: true,
    },
};

const isStorybook = process.env.NODE_ENV === 'storybook';

const config = isStorybook ? storybookConfig : appConfig;
const callback = isStorybook ? undefined : overrideCallback;

i18n.use(Backend).use(initReactI18next).init(config, callback);
// Add x-client to get the translations from the extended schema tables
i18n.services.backendConnector.backend.options.customHeaders = {
    'x-client': getClientBind(),
};
export default i18n;

const BLOCK_NAMESPACE = 'blocks_manty';
const BLOCK_NAMESPACE_SHORT = 'blocks_manty_short';
const BLOCK_NAMESPACE_DESCRIPTIONS = 'blocks_manty_descriptions';
const BLOCK_NAMESPACE_VALUES = 'blocks_manty_values';
const SPECIAL_DATE_KEYS = ['default_time', 'month', 'year', 'day', 'quarter'];
const SOFTWARE_NAME_MAPPING = {
    CIRIL_FINANCES: 'blocks_manty_software_CIRIL',
    ASTRE_GF: 'blocks_manty_software_ASTRE',
};

export const translateComponent = (block?: string | null): string => {
    if (!block) return '';
    return i18n.t(`components:${camelize(block)}`, { ns: 'components' });
};

const getBlockNamespaceUsed = (
    block: string,
    useShort: boolean,
    softwares: Set<string>,
    useDescriptions: boolean,
): string => {
    if (softwares.size > 0) {
        for (const softwareKey of Object.keys(SOFTWARE_NAME_MAPPING)) {
            if (softwares.has(softwareKey)) {
                const blockNameSoftware = SOFTWARE_NAME_MAPPING[softwareKey];
                if (i18n.exists(`${blockNameSoftware}:${camelize(block)}`)) {
                    return blockNameSoftware;
                }
            }
        }
    }

    let blockNamespaceused = BLOCK_NAMESPACE;

    if (
        useShort &&
        i18n.exists(`${BLOCK_NAMESPACE_SHORT}:${camelize(block)}`)
    ) {
        blockNamespaceused = BLOCK_NAMESPACE_SHORT;
    }

    if (
        useDescriptions &&
        i18n.exists(`${BLOCK_NAMESPACE_DESCRIPTIONS}:${camelize(block)}`)
    ) {
        blockNamespaceused = BLOCK_NAMESPACE_DESCRIPTIONS;
    }

    return blockNamespaceused;
};

export function translateBlock(
    block?: string | null,
    useShort: boolean = false,
    useBlockName: boolean = true,
    softwares: Set<string> = new Set<string>(),
    useDescriptions: boolean = false,
): string {
    let translation;
    if (!block) return '';

    const blockNamespaceused = getBlockNamespaceUsed(
        block,
        useShort,
        softwares,
        useDescriptions,
    );

    /* TODO: month and year should probably not be displayed in charts. The "month" and "year" keys should be removed
     ** if we prevent isYearlyComparison from being true when displaying a pivot table: the bug was
     ** that a column titled "year" can be displayed under these (invalid) conditions. At least now the label is translated, but
     ** the displayed data is not meaningful anyway.
     */
    const isBlockNotSpecialDateKey = !SPECIAL_DATE_KEYS.includes(
        snakify(block),
    );
    if (!block.includes('.') && isBlockNotSpecialDateKey) {
        translation = i18n.t(`${camelize(block)}.`, { ns: blockNamespaceused });
    } else {
        translation = i18n.t(camelize(block), { ns: blockNamespaceused });
    }
    if (
        translation !== camelize(block) &&
        translation !== `${camelize(block)}.`
    ) {
        return translation;
    }

    // It might be a custom indicator or coming from an SQL database
    if (useBlockName && block.includes('.')) {
        return snakify(block.split('.')[1]).replace(/_/g, ' ');
    }
    return block;
}

/**
 * Same function as above for block values (i.e. backend enum values). No need to modify it.
 * We use a different namespace because we want to return it as is if we don't know it.
 * @param  {string} blockValue
 * @return {string}
 */
export function translateBlockValue(
    blockValue: string,
    useShort: boolean = false,
    softwares: Set<string> = new Set<string>(),
): string {
    let translation = i18n.t(blockValue, { ns: BLOCK_NAMESPACE_VALUES });
    if (translation === blockValue) {
        translation = translateBlock(blockValue, useShort, false, softwares);
    }
    return translation;
}

/**
 * Translate block description.
 * @param  {string} value
 * @return {string}
 */
export function translateBlockDescription(value: string): string {
    return translateBlock(value, false, false, new Set(), true);
}

export function translateBlockValueWithLabel<T>(
    obj: {
        label: string;
    } & T,
): { label: string } & T {
    return {
        ...obj,
        label: i18n.t(obj.label, { ns: BLOCK_NAMESPACE_VALUES }),
    };
}
