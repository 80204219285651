import _ from 'lodash';
import { createTheme, alpha } from '@mui/material/styles';
import { getHomePageUrl } from 'helpers/helpers';
import { trackUser } from 'helpers/trackerHelpers';
import { getClientBind } from 'store/APIClient';
import { THEME_BASE, PRIMARY_COLOR, SECONDARY_COLOR } from 'styles/theme';
import {
    ACCESS_DAF,
    ADMIN,
    ADMIN_CATEGORIES,
    SUPER_FINANCES_WRITER,
    ACCESS_BUDGETS_GESTION,
    SUPPORT,
    ACCESS_CADRAGE_GESTION,
} from 'business/constants';
import { ProtectionT, ScopeT, UserT } from 'business/definitions';
import { ESSENTIAL } from 'routes/Support/components/ClientSupport/components/ClientConfiguration/ModuleForm/helpers/definitions';
import { datadogRum } from '@datadog/browser-rum';
import { SnackbarVariant } from 'components/SnackbarNotification/SnackbarNotification';
import { AuthResponse } from 'routes/LoginPage/models';

export type VisionState = {
    isAuthenticated: boolean;
    isAdmin: boolean;
    hasAccessDaf: boolean;
    isSuperFinancesWriter: boolean;
    isAdminCategories: boolean;
    hasAccessCadrageGestion: boolean;
    hasAccessBudgetsGestion: boolean;
    isSupportUser: boolean;
    showSnack: boolean;
    initialLoad: boolean;
    user: UserT;
    client: ClientT;
    snackAction: FlowAnyFunction | null | undefined;
    snackText: string;
    snackType: SnackbarVariant;
    protections: ProtectionT[];
    scopes: ScopeT[];
    isPdfModeActivated: boolean;
    homePageUrl: string | null | undefined;
};

const DEFAULT_THEME = createTheme(THEME_BASE);

const emptyUser: UserT = {
    authMethods: [],
    email: '',
    gestionnaireNodes: [],
    homeDashboardSlug: '',
    id: 0,
    isActive: false,
    isAdmin: false,
    isScopeGestionnaireUnrestricted: false,
    isScopeOrgChartUnrestricted: false,
    orgChartNodes: [],
    rights: [],
    roles: [],
    scopes: [],
    tutorialSteps: {},
    firstname: '',
    lastname: '',
    gender: undefined,
    job: '',
};

const visionState: VisionState = {
    isAuthenticated: false,
    hasAccessDaf: false,
    isSuperFinancesWriter: false,
    isAdminCategories: false,
    hasAccessCadrageGestion: false,
    hasAccessBudgetsGestion: false,
    isAdmin: false,
    isSupportUser: false,
    initialLoad: true,
    showSnack: false,
    user: emptyUser,
    client: {
        authMethods: [],
        bind: getClientBind(),
        isValidBind: true,
        hasDatagouv: false,
        logoUrl: '',
        name: '',
        primaryColor: PRIMARY_COLOR,
        secondaryColor: SECONDARY_COLOR,
        theme: DEFAULT_THEME,
        modules: [],
        extraFeatures: [],
        decisionPerimeters: [],
        owner: '',
        homePageUrl: null,
        hasUsageHistory: false,
    },
    snackAction: null,
    snackText: '',
    snackType: 'info',
    protections: [],
    scopes: [],
    isPdfModeActivated: window.location.pathname.endsWith('/pdf'),
    homePageUrl: null,
};

const visionModel = {
    state: visionState,
    reducers: {
        notifyUser(
            state: VisionState,
            payload: {
                message: string;
                snackAction?: {
                    message: string;
                    onClick: FlowAnyFunction;
                };
                snackType?: SnackbarVariant;
                errorCode?: number;
            },
        ): VisionState {
            const { message, snackAction, snackType } = payload;

            // If it is an UnAuthorized error with a non-authenticated user,
            // we skip the error to avoid spamming
            if (payload.errorCode === 401 && !state.isAuthenticated) {
                return { ...state };
            }

            return {
                ...state,
                showSnack: true,
                snackText: message,
                snackAction,
                snackType: snackType || 'info',
            };
        },
        closeSnack(state: VisionState): VisionState {
            return {
                ...state,
                showSnack: false,
            };
        },
        setClientDetails(state: VisionState, payload: ClientT): VisionState {
            let { theme } = state.client;
            if (payload.primaryColor && payload.secondaryColor) {
                theme = createTheme(
                    _.merge(THEME_BASE, {
                        palette: {
                            primary: {
                                main: payload.primaryColor,
                                light: alpha(payload.primaryColor, 0.15),
                            },
                            secondary: {
                                main: payload.secondaryColor,
                            },
                        },
                    }),
                );
            }
            document.title = `Manty ${payload.name}`;

            return {
                ...state,

                initialLoad: false,
                client: { ...payload, theme },
                homePageUrl: getHomePageUrl(payload),
            };
        },
        setAuthenticatedState(
            state: VisionState,
            payload: AuthResponse,
        ): VisionState {
            const { token, user } = payload;
            localStorage.setItem('token', token);
            const isEssential = state.client.extraFeatures.includes(ESSENTIAL);
            trackUser(user, state.client.modules, isEssential);
            return {
                ...state,

                initialLoad: false,
                isAuthenticated: true,
                isAdmin: user.roles.includes(ADMIN),
                hasAccessDaf: user.roles.includes(ACCESS_DAF),
                isSuperFinancesWriter: user.roles.includes(
                    SUPER_FINANCES_WRITER,
                ),
                isAdminCategories: user.roles.includes(ADMIN_CATEGORIES),
                hasAccessCadrageGestion: user.roles.includes(
                    ACCESS_CADRAGE_GESTION,
                ),
                hasAccessBudgetsGestion: user.roles.includes(
                    ACCESS_BUDGETS_GESTION,
                ),
                isSupportUser: user.roles.includes(SUPPORT),
                user,
            };
        },
        setLogoutState(state: VisionState): VisionState {
            localStorage.removeItem('token');
            return {
                ...state,
                isAuthenticated: false,
                isAdmin: false,
                hasAccessDaf: false,
                isSuperFinancesWriter: false,
                hasAccessCadrageGestion: false,
                isAdminCategories: false,
                hasAccessBudgetsGestion: false,
                user: emptyUser,
            };
        },
        setScopes(
            state: VisionState,
            payload: { scopes: ScopeT[] },
        ): VisionState {
            return {
                ...state,
                scopes: payload.scopes,
            };
        },
        setProtections(
            state: VisionState,
            payload: { protections: ProtectionT[] },
        ): VisionState {
            return {
                ...state,
                protections: payload.protections,
            };
        },
        setUserTutorialSteps(
            state: VisionState,
            payload: { tutorialSteps: { [key: string]: boolean } },
        ): VisionState {
            return {
                ...state,
                user: {
                    ...state.user,
                    tutorialSteps: payload.tutorialSteps,
                },
            };
        },
        updateUserInformation(state: VisionState, newUser: UserT): VisionState {
            return {
                ...state,
                user: newUser,
            };
        },
    },
    // dispatch is 'any' until we upgrade rematch to correctly type it
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    effects: (dispatch: any) => ({
        async logout() {
            return dispatch.API.post({
                params: {
                    endpoint: 'auth/logout',
                },
                successAction: () => {
                    if (PROD || STAGING || PRESENTATION) {
                        datadogRum.clearUser();
                    }

                    dispatch.vision.setLogoutState();
                    // TODO: Use a more elegant way to reset the state.
                    dispatch({
                        type: 'RESET_STATE',
                    });
                },
            });
        },

        async fetchClientDetails() {
            const bind = getClientBind();
            return dispatch.API.get({
                params: {
                    endpoint: `meta/client/${bind}`,
                },
                successAction: (response: Omit<ClientT, 'isValidBind'>) => {
                    dispatch.vision.setClientDetails({
                        ...response,
                        isValidBind: true,
                    });
                },
                failureAction: (status: number) => {
                    if (status === 404) {
                        dispatch.vision.setClientDetails({
                            ...visionState.client,
                            isValidBind: false,
                        });
                    }
                },
            });
        },

        async checkAuthStatus(payload: { shouldBlacklist: boolean }) {
            return dispatch.API.get({
                params: {
                    endpoint: payload.shouldBlacklist
                        ? 'auth/status?blacklist=true'
                        : 'auth/status',
                },
                // @ts-expect-error [TS migration] Was not detected by flow
                successAction: (response) => {
                    this.setAuthenticatedState(response);
                },
                failureAction: () => {
                    this.setLogoutState();
                },
            });
        },

        async fetchScopes() {
            dispatch.API.get({
                params: { endpoint: 'auth/scopes' },
                // @ts-expect-error [TS migration] Was not detected by flow
                successAction: (response) => {
                    this.setScopes({ scopes: response.scopes });
                },
            });
        },

        async fetchProtections() {
            dispatch.API.get({
                params: { endpoint: 'auth/protections' },
                // @ts-expect-error [TS migration] Was not detected by flow
                successAction: (response) => {
                    this.setProtections({ protections: response.protections });
                },
            });
        },

        async updateTutorialSteps(payload: {
            tutorialSteps: { [key: string]: boolean };
        }) {
            const { tutorialSteps } = payload;
            const dataToSend = {
                tutorialSteps,
            };
            dispatch.API.put({
                params: {
                    endpoint: `auth/user_tutorial`,
                    body: dataToSend,
                },
                successAction: () => {
                    this.setUserTutorialSteps({
                        tutorialSteps,
                    });
                },
            });
        },
    }),
};

export default visionModel;
