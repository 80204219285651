/**
This module contains the configuration for SSO handled with Azure AD B2C.

Most of our clients are using this. When enabling SSO for a new bind using Azure, no changes are required in the code
but you need to create a new signup/signin policy in the Azure portal. Check Notion for the documentation.
 */
import { LogLevel } from '@azure/msal-browser';

const MANTY_B2C_TENANT_NAME = 'mantyb2c';
const MANTY_B2C_API_APP_ID = '7df12acc-5fe0-4fc6-8736-b47edf5c8eed';
const MANTY_B2C_SPA_APP_ID = '27d16041-addc-49d0-a70d-a4ec7d4a7526';

const getBindB2CPolicies = (bind: string) => {
    const signUpSignIn = `B2C_1_${bind}_signup_and_signin`;
    const authorityDomain = `${MANTY_B2C_TENANT_NAME}.b2clogin.com`;
    return {
        names: {
            signUpSignIn,
        },
        authorities: {
            signUpSignIn: {
                // looks like https://mantyb2c.b2clogin.com/mantyb2c.onmicrosoft.com/B2C_1_clamart_signup_and_signin
                authority: `https://${authorityDomain}/${MANTY_B2C_TENANT_NAME}.onmicrosoft.com/${signUpSignIn}`,
            },
        },
        authorityDomain,
    };
};

export const getAzureConfig = (bind: string) => {
    const B2CPolicies = getBindB2CPolicies(bind);
    return {
        auth: {
            clientId: MANTY_B2C_SPA_APP_ID,
            authority: B2CPolicies.authorities.signUpSignIn.authority,
            knownAuthorities: [B2CPolicies.authorityDomain],
            redirectUri: window.location.origin,
        },
        cache: {
            cacheLocation: 'sessionStorage',
            storeAuthStateInCookie: false,
        },
        system: {
            loggerOptions: {
                loggerCallback: (
                    level: LogLevel,
                    message: string,
                    containsPii: boolean,
                ) => {
                    if (containsPii) {
                        return;
                    }
                    switch (level) {
                        case LogLevel.Info:
                            // eslint-disable-next-line no-console
                            console.info(message);
                            return;
                        case LogLevel.Verbose:
                            // eslint-disable-next-line no-console
                            console.debug(message);
                            return;
                        case LogLevel.Warning:
                            console.warn(message);
                            return;
                        case LogLevel.Error:
                        default:
                            console.error(message);
                    }
                },
            },
        },
    };
};

export const AZURE_SCOPES = [
    'openid',
    `https://${MANTY_B2C_TENANT_NAME}.onmicrosoft.com/${MANTY_B2C_API_APP_ID}/all.write`,
];
