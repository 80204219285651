import { PopupRequest, PublicClientApplication } from '@azure/msal-browser';
import {
    AZURE_SCOPES,
    getAzureConfig,
} from 'routes/LoginPage/sso/config/azure';
import {
    isBindUsingOIDC,
    OIDC_CONFIG_FOR_BIND,
} from 'routes/LoginPage/sso/config/oidc';

const getBindSSOConfig = (bind: string) =>
    isBindUsingOIDC(bind) ? OIDC_CONFIG_FOR_BIND[bind] : getAzureConfig(bind);

const getPopupRequest = (bind: string): PopupRequest =>
    isBindUsingOIDC(bind) ? { scopes: ['openid'] } : { scopes: AZURE_SCOPES };

/**
 * Shows a popup to the user to login with the SSO issuer and returns the access token if successful.
 * It uses msal-browser, which is the official client for Azure AD, but it's compatible with standards
 * like OIDC, so we can use it with other SSO issuers.
 *
 * @returns the short lived access token that we exchange for a Manty token in the backend. This token
 * contains the user's identity (email, name, etc) so we can match it with a Manty user.
 */
export const getSSOAccessToken = async (bind: string) => {
    // we instantiate the MSAL client with the configuration for the bind.
    const msal = new PublicClientApplication(getBindSSOConfig(bind));
    const request = getPopupRequest(bind);
    // this opens a popup showing the issuer login page (Azure, Keycloak...) and return the access token if successful
    const { accessToken } = await msal.loginPopup(request);
    return accessToken;
};
