import i18n from 'i18n';
import APICall from './APIClient';

const API = {
    state: {} as FlowAnyObject,
    // dispatch is 'any' until we upgrade rematch to correctly type it
    effects: (dispatch: FlowAnyFunction) => ({
        /**
         * Effect used to make API calls.
         * If the call is successful, and if the backend returns a message, the user will be notified.
         * If the call fails, the user will be notified regardless, with the response from the backend
         * if it exists or with a generic error message.
         * Example usage:
         * const response = await dispatch.API.call(
         *    {endpoint:'some/endpoint'},
         *    {type: 'some_endpoint_success'},
         *    {type: 'some_endpoint_failure'},
         * );
         * @param  {Object} params
         * @param  {Action} successAction Action that will be dispatched on success.
         * @param  {Action} successNotifyAction Action that will be triggered by clicking the
         * button on the notification's snackbar.
         * @param  {Action} failureAction Action that will be dispatched on failure.
         * @return {Object}
         */
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        async call<T extends Blob | { data: any }>(payload: {
            params: {
                endpoint: string;
                method: string;
                body?: { [key: string]: object };
                headers?: { [key: string]: string };
            };
            successNotifyAction?: (arg1?: T) => {
                message: string;
                onClick: FlowAnyFunction;
            };
            successAction?: (arg1: T) => void;
            failureAction?: (
                arg1: number | null,
                arg2: T | null | undefined,
            ) => void;
            showFailureNotification?: boolean;
        }) {
            const response = await APICall<T>(payload.params);
            if (response.ok) {
                // Request is successful, launching success action and message
                if (response.content.message) {
                    const data =
                        response.content instanceof Blob
                            ? response.content
                            : response.content.data;
                    dispatch.vision.notifyUser({
                        message: response.content.message,
                        snackAction: payload.successNotifyAction
                            ? payload.successNotifyAction(data)
                            : undefined,
                    });
                }

                if (payload.successAction) {
                    if (response.content instanceof Blob) {
                        payload.successAction(response.content);
                    } else {
                        payload.successAction(response.content.data);
                    }
                }
            } else {
                // Request has failed, launching failure action and message
                if (
                    payload.showFailureNotification === undefined ||
                    payload.showFailureNotification
                ) {
                    // Show failure notification by default
                    let message;
                    if (response.isNetworkError) {
                        message = i18n.t('errors.networkError');
                    } else {
                        message =
                            response.content && response.content.message
                                ? response.content.message
                                : i18n.t('errors.genericError');
                    }
                    dispatch.vision.notifyUser({
                        message,
                        snackType: 'warning',
                        errorCode: response.statusCode,
                    });
                }
                if (payload.failureAction) {
                    if (response.statusCode) {
                        payload.failureAction(
                            response.statusCode,
                            response.content,
                        );
                    } else {
                        // @ts-expect-error [TS migration] Was not detected by flow
                        payload.failureAction(response.content);
                    }
                }
            }
            // Returning the response anyway for further use.
            // eslint-disable-next-line no-console
            // console.log('DispatchAPICall', response, payload.params);
            return response.content;
        },

        // dispatch.API.get(params)
        get<T>(payload: {
            params: {
                endpoint: string;
                body?: FlowAnyObject;
            };
            successAction?: (arg1: T) => void;
            failureAction?: (arg1: T | null | undefined) => void;
            showFailureNotification?: boolean;
        }) {
            return dispatch.API.call({
                ...payload,
                params: { ...payload.params, method: 'GET' },
            });
        },

        post<T>(payload: {
            params: {
                endpoint: string;
                body?: FlowAnyObject;
            };
            successAction?: (arg1: T) => void;
            failureAction?: (arg1: T | null | undefined) => void;
        }) {
            return dispatch.API.call({
                ...payload,
                params: { ...payload.params, method: 'POST' },
            });
        },

        put<T>(payload: {
            params: {
                endpoint: string;
                body?: FlowAnyObject;
            };
            successAction?: (arg1: T) => void;
            failureAction?: (arg1: T | null | undefined) => void;
        }) {
            return dispatch.API.call({
                ...payload,
                params: { ...payload.params, method: 'PUT' },
            });
        },

        patch<T>(payload: {
            params: {
                endpoint: string;
                body?: FlowAnyObject;
            };
            successAction?: (arg1: T) => void;
            failureAction?: (arg1: T | null | undefined) => void;
        }) {
            return dispatch.API.call({
                ...payload,
                params: { ...payload.params, method: 'PATCH' },
            });
        },

        delete<T>(payload: {
            params: {
                endpoint: string;
                body?: FlowAnyObject;
            };
            successAction?: (arg1: T) => void;
            failureAction?: (arg1: T | null | undefined) => void;
        }) {
            return dispatch.API.call({
                ...payload,
                params: { ...payload.params, method: 'DELETE' },
            });
        },
    }),
};

export default API;
