import * as React from 'react';
import Button from '@mui/material/Button';
import oops from './assets/oops.png';

import './_errorBoundary.scss';
import { useTranslation } from 'react-i18next';

function ErrorComponent() {
    const { t } = useTranslation();
    return (
        <div
            className="error-boundary e2e-error-boundary"
            data-testid="error-boundary"
        >
            <img src={oops} alt="no parachute" />
            <h1>{t('errorBoundary.title')}</h1>
            <div className="message">
                {t('errorBoundary.message')}
                <br />
                {t('errorBoundary.secondaryMessage')}
            </div>
            <Button
                // we cannot pass window.location.reload directly because
                // `this` must be window.location when reload() is called
                onClick={() => window.location.reload()}
                variant="outlined"
            >
                {t('common.refresh')}
            </Button>
        </div>
    );
}

export default ErrorComponent;
