import { Configuration, ProtocolMode } from '@azure/msal-browser';

const getOIDCConfig = ({
    issuer,
    clientId,
}: {
    issuer: string;
    clientId: string;
}): Configuration => ({
    auth: {
        protocolMode: ProtocolMode.OIDC,
        authorityMetadata: JSON.stringify({
            authorization_endpoint: `${issuer}/protocol/openid-connect/auth`,
            token_endpoint: `${issuer}/protocol/openid-connect/token`,
            issuer,
            userinfo_endpoint: `${issuer}/protocol/openid-connect/userinfo`,
        }),
        authority: issuer,
        clientId,
        knownAuthorities: [issuer],
        redirectUri: `${window.location.origin}/login`,
    },
});

export const OIDC_CONFIG_FOR_BIND = {
    cove: getOIDCConfig({
        issuer: 'https://auth.lacove.fr/realms/externe',
        clientId: 'manty',
    }),
    ampmetropole: getOIDCConfig({
        issuer: 'https://appidpm-t.ampmetropole.fr/auth/realms/Extranet',
        clientId: 'MANTY_TEST', // TODO: change me with production id when ready
    }),
} as const;

type OIDCBind = keyof typeof OIDC_CONFIG_FOR_BIND;

export const isBindUsingOIDC = (bind: string): bind is OIDCBind =>
    Object.keys(OIDC_CONFIG_FOR_BIND).includes(bind);
