import { init, RematchDispatch, RematchRootState } from '@rematch/core';
import loadingPlugin, { ExtraModelsFromLoading } from '@rematch/loading';

// models
import mappings from 'routes/Mappings/models';
import integrations from 'routes/IntegrationsList/models';
import loginPage from 'routes/LoginPage/models';
import support from 'routes/Support/models';
import resetPassword from 'routes/ResetPassword/models';
import vision from 'components/App/models';

// API middleware
import API from './rematchAPICall';

const models = {
    vision,
    loginPage,
    resetPassword,
    integrations,
    mappings,
    API,
    support,
};

type RootModel = typeof models;
type FullModel = ExtraModelsFromLoading<RootModel>;

export type Dispatch = RematchDispatch<RootModel>;
export type RootState = RematchRootState<RootModel, FullModel>;

// ======================================================
// Store Instantiation
// ======================================================
const store = init<RootModel, FullModel>({
    models,
    plugins: [loadingPlugin()],
    redux: {
        rootReducers: {
            RESET_STATE: (rootState) => ({ vision: rootState.vision }),
        },
    },
});

// ========================================================
// History and Store Instantiation
// ========================================================
// We need store access to dispatch actions
export const { dispatch } = store;

export default store;
