import i18n from 'i18n';

export const MONTHS = [
    'Jan',
    'Fév',
    'Mar',
    'Avr',
    'Mai',
    'Juin',
    'Juil',
    'Août',
    'Sep',
    'Oct',
    'Nov',
    'Déc',
];

export const FULL_MONTHS = [
    'Janvier',
    'Février',
    'Mars',
    'Avril',
    'Mai',
    'Juin',
    'Juillet',
    'Août',
    'Septembre',
    'Octobre',
    'Novembre',
    'Décembre',
];

export const DAYS = MONTHS.flatMap((month, index) => {
    const numberOfDays = new Date(2020, index + 1, 0).getDate();
    return Array.from(Array(numberOfDays), (e, day) => `${day + 1} ${month}`);
});

export const QUARTERS = [
    'T1',
    'T1',
    'T1',
    'T2',
    'T2',
    'T2',
    'T3',
    'T3',
    'T3',
    'T4',
    'T4',
    'T4',
];

export const formatDate = (date: Date): string =>
    date.toLocaleString(i18n.language, { dateStyle: 'short' });

// The backend and the frontend don't render the same time sometimes
// when we group by data with a time.
// The difference is in matter of hours. As the lowest time grouping is in day yet, we can consider that if two timestamps have
// a difference lower than 12 hours, they are the same dates
export function hasLessThan12HoursDifference(
    timestamp1: number,
    timestamp2: number,
): boolean {
    const difference = timestamp1 - timestamp2;
    const hoursDifference = Math.abs(Math.floor(difference / 1000 / 60 / 60));
    return hoursDifference < 12;
}
