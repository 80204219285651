import { mantyIntegrations } from './fullIntegrationList';
import { State, IntegrationT } from './types';

const integrationsState: State = {
    isModalVisible: false,
    selected: null,
    // @ts-expect-error [TS migration] (previously $FlowFixMe) flow exact type migration: type issue
    list: mantyIntegrations,
};

const integrations = {
    state: integrationsState,
    reducers: {
        setIntegration(state: State, data: IntegrationT): State {
            const list: IntegrationT[] = state.list.map(
                (integration: IntegrationT) =>
                    data.software === integration.software
                        ? { ...integration, ...data }
                        : integration,
            );
            return {
                ...state,
                list,
                selected:
                    // We only update the selected integration if it matches the fetched data
                    state.selected && state.selected.software === data.software
                        ? {
                              ...state.selected,
                              ...data,
                              // We leave authData to avoid resetting the form while the user is filing it
                              authData: state.selected.authData,
                          }
                        : state.selected,
            };
        },
        setIntegrations(state: State, data: IntegrationT[]): State {
            const list: IntegrationT[] = state.list.map((integration) => {
                const extendedData = data.find(
                    (i) =>
                        i.software.toLowerCase() ===
                        integration.software.toLowerCase(),
                );
                if (extendedData) {
                    return { ...integration, ...extendedData, isAdded: true };
                }
                return { ...integration };
            });
            return {
                ...state,
                list,
            };
        },
    },
    // dispatch is 'any' until we upgrade rematch to correctly type it
    effects: (dispatch: any) => ({
        async fetch() {
            await dispatch.API.get({
                params: { endpoint: 'integrations' },
                // @ts-expect-error [TS migration] (previously $FlowFixMe) I don’t know how to fix it
                successAction: (data) => this.setIntegrations(data),
            });
        },

        async fetchIntegration(id: number) {
            await dispatch.API.get({
                params: { endpoint: `integrations/${id}` },
                // @ts-expect-error [TS migration] (previously $FlowFixMe) I don’t know how to fix it
                successAction: (data) => this.setIntegration(data),
            });
        },
    }),
};

export default integrations;
