import * as React from 'react';
import { createBrowserRouter } from 'react-router-dom';
import App from 'routes';
import Loading from 'components/Loading';

// This type definition is needed because the router type is not exposed directly from react-router-dom
// https://github.com/remix-run/react-router/issues/10787
export const router: ReturnType<typeof createBrowserRouter> =
    createBrowserRouter([
        // match everything with "*"
        {
            path: '*',
            element: (
                <React.Suspense fallback={<Loading />}>
                    <App />
                </React.Suspense>
            ),
        },
    ]);
