import { useSelector, useDispatch } from 'react-redux';
import { RootState, Dispatch } from './store';

const useAppSelector = <T>(
    selector: (state: RootState) => T,
    equalityFn?: (left: T, right: T) => boolean,
): T => useSelector<RootState, T>(selector, equalityFn);

const useAppDispatch = () => useDispatch<Dispatch>();

export { useAppSelector as useSelector, useAppDispatch as useDispatch };
