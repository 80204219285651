import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import ErrorComponent from 'components/ErrorBoundary/ErrorComponent';
import Typography from '@mui/material/Typography';

type Props = {
    error?: Error | null;
    pastDelay?: boolean;
    small?: boolean;
    message?: string | null;
    fullWidth?: boolean;
};

function Loading({
    error = null,
    pastDelay = true,
    small = false,
    message = null,
    fullWidth = false,
}: Props): React.ReactElement {
    if (error) {
        console.error(error);
        return <ErrorComponent />;
    }
    if (!pastDelay) {
        // Modified automatically by react-loadable
        return <></>;
    }

    // center the loading image
    const containerStyle: React.CSSProperties = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        height: '100%',
        padding: '0.5rem',
        flex: fullWidth ? 1 : 0,
    };

    return (
        <div style={containerStyle} className="manty-loader">
            <CircularProgress size={small ? 30 : 40} />
            {message && (
                <Typography sx={{ marginTop: '1rem' }}> {message} </Typography>
            )}
        </div>
    );
}
export default Loading;
