// Functions camelizing or snakifying objects, to ensure smooth transition with the backend.
import _ from 'lodash';

const isObject = (obj: unknown): obj is object => obj === Object(obj);

const isArray = (obj: unknown): obj is unknown[] =>
    Object.prototype.toString.call(obj) === '[object Array]';

const isDateObject = (obj: unknown): obj is Date =>
    Object.prototype.toString.call(obj) === '[object Date]';

const isRegExp = (obj: unknown): obj is RegExp =>
    Object.prototype.toString.call(obj) === '[object RegExp]';

const isBoolean = (obj: unknown): obj is boolean =>
    Object.prototype.toString.call(obj) === '[object Boolean]';

const isNumerical = (obj: string | number): obj is number =>
    // I am not sure how this function is useful, apart for type guarding (but it was written before
    // we started using typescript).
    // Currently, the function only returns true only when the input is already a number.
    // Maybe the strict equality (===) should be non-strict (==), so that a string input
    // that could represent a number would give a return value of true.
    // @ts-expect-error parseFloat can take a number but its type declaration doesn't show it,
    // we've been using it like this before switching to TS.
    parseFloat(obj) === obj;

const isSymbol = (obj: unknown): obj is symbol => typeof obj === 'symbol';

export const processTopLevelKeys = <T>(
    convert: (k: string) => string,
    obj: T,
): T => {
    if (
        !isObject(obj) ||
        isArray(obj) ||
        isDateObject(obj) ||
        isRegExp(obj) ||
        isBoolean(obj)
    ) {
        return obj;
    }

    const output: Record<string | number | symbol, unknown> = {};
    Object.keys(obj).forEach((key) => {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
            if (isSymbol(key) || isNumerical(key)) {
                output[key] = obj[key];
            } else {
                output[convert(key)] = obj[key];
            }
        }
    });
    return output as T;
};

export function processKeys<T extends Record<string, unknown>>(
    convert: <U extends string>(text: U) => Camelize<U>,
    obj: T,
): CamelizeKeys<T>;
export function processKeys<T extends Record<string, unknown>[]>(
    convert: <U extends string>(text: U) => Camelize<U>,
    obj: T,
): CamelizeKeys<T[number]>[];
export function processKeys<T extends Record<string, unknown>>(
    convert: <U extends string>(text: U) => Snakify<U>,
    obj: T,
): SnakifyKeys<T>;
export function processKeys<T extends Record<string, unknown>[]>(
    convert: <U extends string>(text: U) => Snakify<U>,
    obj: T,
): SnakifyKeys<T[number]>[];
export function processKeys<T extends unknown[]>(
    convert: (k: string) => string,
    obj: T,
): T;
export function processKeys<T>(convert: (k: string) => string, obj: T): T;
export function processKeys<T>(convert: (k: string) => string, obj: T): T {
    if (
        !isObject(obj) ||
        isDateObject(obj) ||
        isRegExp(obj) ||
        isBoolean(obj)
    ) {
        return obj;
    }

    if (isArray(obj)) {
        const output = Array.from<unknown>([]) as typeof obj;
        for (let i = 0; i < obj.length; i += 1) {
            output.push(processKeys(convert, obj[i]));
        }
        return output;
    }
    const output: Record<string, unknown> = {};
    Object.keys(obj).forEach((key) => {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
            output[convert(key as string)] = processKeys(convert, obj[key]);
        }
    });

    return output as T;
}

export const camelize = <T extends string>(text: T): Camelize<T> => {
    if (text === null || !text) {
        return '' as Camelize<T>;
    }
    if (isNumerical(text)) {
        return text as Camelize<T>;
    }
    const responseString = text.replace(/[-_\s]+(.)?/g, (match, chr) =>
        chr ? chr.toUpperCase() : '',
    );
    // Ensure 1st char is always lowercase
    return (responseString.substr(0, 1).toLowerCase() +
        responseString.substr(1)) as Camelize<T>;
};

export const camelizeKeys = <KeyT extends string, ValueT>(
    object: Record<KeyT, ValueT>,
): CamelizeKeys<Record<KeyT, ValueT>> => processKeys(camelize, object);

/** We have a custom snakify, whose return type is not always a string.
 * Our function keep the dot '.' in the string.
 */
export const snakify = <T extends string>(text: T): Snakify<T> => {
    if (!text || text == null) {
        return '' as Snakify<T>;
    }
    if (isNumerical(text)) {
        // TODO don't we always want to return a string?
        return text as Snakify<T>;
    }
    // NB we use loadash and not a custom regex because it's hard with regex to
    // handle Test123 -> test_123 (we get test123 or test_1_2_3)
    // NB _.snakeCase remove the dot, that's why we split first.
    return text.split('.').map(_.snakeCase).join('.') as Snakify<T>;
};

export const snakifyKeys = <KeyT extends string, ValueT>(
    object: Record<KeyT, ValueT>,
): SnakifyKeys<Record<KeyT, ValueT>> => processKeys(snakify, object);

export const uncapitalize = <S extends string>(str: S): Uncapitalize<S> => {
    if (str === '') return '' as Uncapitalize<S>;
    return (str.charAt(0).toLowerCase() + str.slice(1)) as Uncapitalize<S>;
};
