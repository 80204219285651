import { Typography } from '@mui/material';
import { HOME_PAGE_URL } from 'components/App/components/routes';
import React from 'react';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { getClientBind, RQAPICall } from 'store/APIClient';
import Flex from 'ui/Flex/Flex';
import StyPageContainer from 'ui/PageContainer/PageContainer';

export const MAINTENANCE_ERROR_CODE = 503;

const Maintenance = (): React.ReactElement => {
    const bind = getClientBind();
    const navigate = useNavigate();

    const TWO_MINUTES = 2 * 60 * 1000;
    const { data: statusCode } = useQuery(
        'serverStatus',
        async () => {
            const response = await RQAPICall({
                endpoint: `meta/client/${bind}`,
                method: 'GET',
            });
            return response.statusCode;
        },
        {
            refetchInterval: TWO_MINUTES,
        },
    );
    React.useEffect(() => {
        if (statusCode === 200) {
            navigate(HOME_PAGE_URL);
        }
    }, [statusCode]);

    return (
        <StyPageContainer sx={{ px: '2.5rem' }}>
            <Typography
                variant="h1"
                color="primary"
                sx={{ mb: '1.5rem', mt: '2.5rem', fontSize: '2rem' }}
            >
                Maintenance en cours
            </Typography>

            <Flex direction="column" sx={{ gap: '1em', maxWidth: '70ch' }}>
                <Typography>
                    Notre service est en cours de maintenance.
                </Typography>
                <Typography>
                    Nous nous excusons pour tout désagrément que cela pourrait
                    causer et nous vous invitons à revenir plus tard. Cette page
                    se rafraîchira automatiquement quand la plateforme sera de
                    nouveau disponible.
                </Typography>

                <Typography sx={{ mt: 2 }}>L&lsquo;équipe Manty</Typography>
            </Flex>
        </StyPageContainer>
    );
};

export default Maintenance;
